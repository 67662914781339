* {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
.page {
  background: white;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-bottom: 24px;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  max-width: 21cm;
  height: 29.7cm;
  padding-left: 2.5cm;
  padding-right: 2.5cm;
  border-radius: 4px; 
  font-size: 14px;
  .page-header
  {
    min-height: 2.5cm;
  }
  .page-header {
    padding-top: 48px;
    padding-bottom: 28px;
  }
  .page-footer {
    z-index:-1; 
  }
  .page-body {
    flex: 1 1 auto;
    min-height: 0;
    .editable {
      cursor: pointer;
    }
  }
}
span.editable.valid {
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  color: #000000;
  padding: 4px 8px;
  word-wrap: break-word;
}
span.editable.invalid {
  background-color: rgba(255, 206, 0, 0.5);
  border-radius: 4px;
  color: #000000;
  padding: 4px 8px;
  word-wrap: break-word;
}
div.editable {
  border-radius: 4px;
}
div.editable.valid {
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  color: #000000;
  padding: 4px 8px;
  word-wrap: break-word;
}
div.editable.invalid {
  background-color: rgba(255, 206, 0, 0.5);
  border-radius: 4px;
  color: #000000;
  padding: 4px 8px;
  word-wrap: break-word;
}

div.editable.optional {
  background-color: rgba(81, 185, 211, 0.5);
  border-radius: 4px;
  color: #000000;
  padding: 4px 8px;
  word-wrap: break-word;
}

div.editable.optional::after {
  content: ' (Optional)';
}

span.editable.optional {
  background-color: rgba(81, 185, 211, 0.5);
  border-radius: 4px;
  color: #000000;
  padding: 4px 8px;
  word-wrap: break-word;
}

span.editable.optional::after {
  content: ' (Optional)';
}
div.editable.hidden {
  display: none;
}

span.editable.hidden {
  display: none;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 100;
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.6em;
}

h3 {
  font-size: 1.2em;
}
/* CSS overrides to fix react-wysiwyg-editor margin issues when justifying text */
.public-DraftStyleDefault-block > div {
  margin: 0px !important;
  display: block !important;
}
.public-DraftStyleDefault-ltr {
  text-align: inherit !important;
}
.rdw-left-aligned-block > div,
.rdw-center-aligned-block > div,
.rdw-right-aligned-block > div {
  display: block !important;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {  
  /* IE11 specific css here */   
  .page {
    display: inherit;
    flex-direction: row;
    height: auto; //this removes the page break in the document preview for ie11 as was causing display issues
    }
  .page-body {
    padding-top: 48px;
  }
}